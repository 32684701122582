.progress {
  overflow: visible !important;
  #gradient > .vertical(#f5f5f5, #f9f9f9);
  .box-shadow(none);
  .border-radius(0);
}
.progress .bar {
  background:none repeat scroll 0 0 #f5f2ed !important;
  margin:0 0 0.5em 0 !important;
  border:none !important;
  float: none;
  height: auto;
  width: auto;
  .box-shadow(none);
  .filled {
    background:none repeat scroll 0 0 #c34500 !important;
    height: 22px !important;
  }
}
.progress .percentage {
  float:right !important;
}
.progress .filled {
  -webkit-transition:0.5s linear !important;
  -moz-transition:0.5s linear !important;
  -o-transition:0.5s linear !important;
  transition:0.5s linear !important;
  -webkit-transition-property:width, background-color !important;
  -moz-transition-property:width, background-color !important;
  -o-transition-property:width, background-color !important;
  transition-property:width, background-color !important;
  background:none repeat scroll 0 0 #c34500 !important;
  height:100% !important;
}
