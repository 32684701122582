#sidebar-first,
#audience-menu {
  
  ul.menu {
    margin: 0;

    li {
      list-style: none;
      list-style-image: none;
      border-top: 1px dotted #252525;
      &.first {
        border-top: none;
      }
      padding: 0;
      margin: 0;
      font-size: 15px;
      
      li {
        border-top: none;
        font-size: 14px;
        &.first a {
          padding-top: 3px;
        }
        a {
          padding-left: 25px; /* level 2 padding */
        }
        li {
          a {
            padding-left: 35px; /* level 3 padding */
          }
          li a {
            padding-left: 45px; /* level 4 padding */
          }
        }
      }

      a {
        color: #252525;
        text-decoration: none;
        display: block;
        padding: 10px 15px; /* level 1 padding */
        &:hover, &:active, &:focus {
          color: #fff;
          background-color: #c34500;
        }
      }
    }
    li.active-trail > a.active:hover,
    li.active-trail > a.active:active,
    li.active-trail > a.active:focus,
    li li > a.active {
      background: #f5f2ed;
      color: inherit;
    }
  }

  .content > ul.menu > li > a.active {
    color: #c34500;
    &:hover, &:active, &:focus  {
      color: #fff;
    }
  }
    
}

#audience-menu {
  display: none; /* this is just for responsive */
}