input.form-submit { /* using a class, once we drop support for ie7 we can use the selector */ 
  background: rgb(195,69,0); /* Old browsers */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2MzNDUwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5ODM2MDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(195,69,0,1) 0%, rgba(152,54,0,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(195,69,0,1)), color-stop(100%,rgba(152,54,0,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(195,69,0,1) 0%,rgba(152,54,0,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(195,69,0,1) 0%,rgba(152,54,0,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(195,69,0,1) 0%,rgba(152,54,0,1) 100%); /* IE10+ */
  background: linear-gradient(top,  rgba(195,69,0,1) 0%,rgba(152,54,0,1) 100%); /* W3C */
  box-shadow: 0 1px 2px rgba(0,0,0,.4);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 10px;
}

.ie input.form-submit {
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c34500', endColorstr='#983600',GradientType=0 ); /* IE6-8 */
}

input.form-submit:hover,
input.form-submit:active,
input.form-submit:focus {
  background: rgb(128,46,1); /* Old browsers */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgwMmUwMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4YzMyMDEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(128,46,1,1) 0%, rgba(140,50,1,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(128,46,1,1)), color-stop(100%,rgba(140,50,1,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(128,46,1,1) 0%,rgba(140,50,1,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(128,46,1,1) 0%,rgba(140,50,1,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(128,46,1,1) 0%,rgba(140,50,1,1) 100%); /* IE10+ */
  background: linear-gradient(top,  rgba(128,46,1,1) 0%,rgba(140,50,1,1) 100%); /* W3C */
  box-shadow: none;
}

input[type="image"] {
  width: auto; /* form inputs that were images were huge, this fixes it (calendar icon in particualr)*/
}

.ie input.form-submit:hover,
.ie input.form-submit:active,
.ie input.form-submit:focus {
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#802e01', endColorstr='#8c3201',GradientType=0 ); /* IE6-8 */
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 2px;
}

/* Webform tweaks */
.block-webform {
  padding: 0 28px;
}

.views-submit-button {
  display: block;
  clear: both;
}

/* any <a> with this class inherits from bootstrap */
.form-actions .btn {
  padding: 10px;
  margin-top: 10px;
  text-decoration: none;
  line-height: 20px;
}