#footer {
  
  .menu-block-wrapper > .menu > li {
    float: left;
    margin-left: 8px;
    max-width: 10.2em;
    &.first {
      margin-left: 0;
    }
    &> a {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .content > .menu > li {
    float: left;
    margin-left: 8px;
    max-width: 10.2em;
    &.first {
      margin-left: 0;
    }
    &> a {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .menu-block-wrapper > .menu li,
  .menu-block-wrapper > .menu li.expanded,
  .content > .menu li,
  .content > .menu li.expanded {
    list-style-image: none;
    list-style-type: none;
  }
  .menu {
    margin-left: 0;
    overflow: auto;
    li {
      margin-left: 3px;
    }
    a {
      text-decoration: none;
      display: block;
      padding-left: 3px;
    }
  }
  ul a {
    text-decoration: none;
    display: block;
    padding-left: 3px;
  }
  
  /* Social media footer links */
  .social-media {
    padding-top: 5px;
    a {
      display: block;
      text-decoration: none;
    }
    i {
      font-size: 18px;
      padding-right: 3px;
    }
  }
  
}