/* We can add any widget styling in here */

/* Accordion styling ***************************************/
.ui-accordion {
  font-size: 14px;
  .ui-accordion-header {
    padding-left: 15px;
    .ui-icon {
      left: 0px;
    }
  }
  .field-items {
    padding: 10px;
  }
  a { 
    color: #c34500;
  }
}