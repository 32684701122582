
/* Accessibility Helping Tools */ 
.word-hide {
  text-indent: -9999em !important;
  /* useful for sprites when you want the content (bg image) to stay, but hide the
  text while still have it available for screen readers */
}

.left-hide,
.sr-only,
.element-invisible {
  position: absolute !important;
  left: -9999em !important;
}



/* For display: none; and other of the like take a look at the bootstrap utilities.less */