/* tablist module styling */
.quicktabs_main.quicktabs-style-basic {
  border: none;
  padding: 0;
  .content {
    margin-bottom: 0;
    padding: 8px 0 0;
  }
}

ul.quicktabs-tabs.quicktabs-style-basic {
  border: none;
  font-family: @sansFontFamily !important;
  border-bottom: 1px solid #eae7e2;
  min-height: 22px;
  padding-right: 0;
  li {
    border: none;
    margin: 0;
    padding: 0;
    &.active {
      border:none;
      a {
        color: #fff;
        background: @linkColor;
      }
    } 
    &:hover {
      border: none;
    }
    a {
      font-family: @sansFontFamily !important;
      font-weight: normal !important;
      color: @grayDarker;
      padding: 4px 8px;
      #gradient .vertical(#f5f2ed, #f6f6f6);
      &:hover, &:active, &:focus {
        color: #fff;
        background: @linkColor;
      }
    }
  }
}

/* New tweaks for horizontal tabs */
.horizontal-tabs {
  ul.horizontal-tabs-list {
    background-color: transparent;
    li {
      background-color: #f5f2ed;
      border-bottom: 1px solid #ccc;
      &.selected {
        background-color: transparent;
        border-bottom: none;
        &.last {
          border-right: none;
        }
        a:hover strong {
          color: #fff;
        }
      }
      a {
        &:hover {
          background-color: #444;
          color: #fff;
        }
      }
    }
    
  }
}