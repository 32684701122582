.page-search {
  #search-form {
    .form-item-keys label {
      display: block;
    }
    #edit-keys {
      height: 32px;
    }
    #edit-submit {
      margin-top: -10px;
    }
  }

  /* Google Results */
  .gsc-result {
    .gs-title {
      height: 1.4em;
    }
  }
}