table {
  width: 99%;
  margin-bottom: 1.5em;
  overflow-x: scroll;
  border: none;
}

table.small {
  width: auto;
}

caption,
thead,
tbody,
tr,
th,
td {
  border-style: solid;
  border-color: #e0e0e0;
  border-width: 0;
}

caption {
  border-top-width: 1px;
  font-weight: bold;
  text-align: left;
  background: rgb(242,242,242); /* Old browsers */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjcwJSIgc3RvcC1jb2xvcj0iI2UwZTBlMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(top,  rgba(242,242,242,1) 0%, rgba(224,224,224,1) 70%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(242,242,242,1)), color-stop(70%,rgba(224,224,224,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(242,242,242,1) 0%,rgba(224,224,224,1) 70%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(242,242,242,1) 0%,rgba(224,224,224,1) 70%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(242,242,242,1) 0%,rgba(224,224,224,1) 70%); /* IE10+ */
  background: linear-gradient(top,  rgba(242,242,242,1) 0%,rgba(224,224,224,1) 70%); /* W3C */
  box-shadow: 0 1px 2px rgba(0,0,0, .1);
  text-shadow: -1px 1px px #aaa;
}

.ie caption {
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#e0e0e0',GradientType=0 ); /* IE6-8 */
}

tr,
.ie7 td {
  border-bottom-width: 1px;
}

tr:nth-child(2n),
tr.even {
  background-color: #fff;  
}

tr.odd,
tr:nth-child(2n+1) {
  background-color: #f5f2ed;
}

#sidebar-first {
  tr.odd, 
  tr:nth-child(2n+1) {
    background-color: transparent;
  }
}

tr:last-child {
  border-bottom: none;
}

td, 
th,
caption {
  padding: 5px 5px 5px 10px;
}

th {
  border-bottom: none;
  border-top-width: 1px;
}

th,
td {
  border-right-width: 1px;
}

td:last-child,
th:last-child {
  border-right-width: 0;
}


/* Table class with no borders or anything */
table.plain {
  tr:nth-child(2n),
  tr:nth-child(2n+1),
  caption,
  td,
  tbody {
    background-color: transparent;
    border-width: 0 !important;
  }
}

table.border {
  border: 1px solid #e0e0e0;
} 